.App {
  text-align: center;
}

#wtypes p {
  color: black;
  font-size: 2rem;
}

#wtypes #sm {
  font-size: 1rem;
}

.dditem:hover {
  background-color: aqua;
}

