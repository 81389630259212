p {
  font-size: 1rem;
}

h4 {
  font-weight: bold;
}

#footer p {
  color: black;
}

ul, li {
  color: black;
}

.row {
  padding-bottom: 15px;
}

.colp {
  padding-top: 10px;
}

.aboutcol {
  color: black;
}