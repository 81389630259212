main {
  min-height: 100vh;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h3 {
  padding: 1rem 0;
  font-size: 1rem;
}

